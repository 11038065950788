import React, {Fragment} from 'react';

import "./ShoppingCart.css";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import {styled, useTheme} from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartList from "./ShoppingCartList";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import {Typography} from "@mui/material";
import {useCart} from "react-use-cart";
import {Link} from "react-router-dom";

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const ShoppingCart = ({ open, handleClose}) => {

    const { totalItems, cartTotal, isEmpty } = useCart();

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <BootstrapDialog
            fullScreen={fullScreen}
            open={open}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            aria-labelledby="responsive-dialog-title"
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {"Cosul Meu ("+ totalItems +")"}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {isEmpty ?
                        <div className="center">
                            <ProductionQuantityLimitsIcon sx={{fontSize: 100}}/>
                            <Typography style={{textAlign: "center"}}>Nu exista produse in cos</Typography>
                        </div>
                        :
                        <ShoppingCartList/>
                    }
                </DialogContentText>
            </DialogContent>
            {!isEmpty ?
                <Fragment>
                <DialogActions style={{justifyContent: "flex-start", paddingLeft: "20px"}}>
                    <Typography style={{fontWeight: "bold"}}> {"Total: " + cartTotal + " Ron"}
                        <Typography style={{color: "rgba(0, 0, 0, 0.6)"}}> Pretul nu include taxa de
                            transport </Typography>
                    </Typography>
                </DialogActions>
                <DialogActions>

                    <Button onClick={handleClose}
                            style={{width: "100%", textTransform: "none", backgroundColor: "#3D1A00", color: "white", margin: "10px", marginTop: "5px"}}
                            autoFocus>
                        <Link to="/checkout" style={{ textDecoration: "none", width: "100%"}}>
                        Continua
                        </Link>
                    </Button>
                </DialogActions>
                </Fragment>
                : ""
            }
        </BootstrapDialog>
    );
};

export default ShoppingCart;