import React, {useEffect} from 'react';
import InfoBar from "../components/InfoBar/InfoBar";
import {Container, Typography} from "@mui/material";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../components/Footer/Footer";
import BottomBar from "../components/BottomBar/BottomBar";


const PoliticaCookies = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0" cart={true}/>

            <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "50px"}}>
                <br/>
                <Typography variant="h5" component="div" style={{textAlign: "center"}}>
                    Politica cookies
                </Typography>
                <br/>
                <br/>

                <Typography><strong>Va rugam să citiți cu atenție informațiile care urmează:</strong></Typography>
                <Typography>
                    Pentru a respecta cerințele stabilite în&nbsp;<strong>&nbsp;Directiva UE din&nbsp;26 mai 2012</strong>&nbsp;și prevederile&nbsp;<strong>Legii nr. 506 din 17 noiembrie 2004</strong>&nbsp;privind prelucrarea datelor cu caracter
                    personal şi protecţia vieţii private în sectorul comunicaţiilor electronice, tuturor vizitatorilor website-ului li se cere consimțământul înaintea transmiterii de cookies în computerele acestora.
                </Typography>
                <Typography>Acest website folosește cookie-uri proprii și de la terți pentru a furniza vizitatorilor o experiență mult mai bună de navigare și servicii adaptate nevoilor și interesului fiecăruia.</Typography>
                <Typography>Cookie-urile joacă un rol important în facilitarea accesului și livrării multiplelor servicii de care utilizatorul se bucură pe internet, cum ar fi:</Typography>
                <ul>
                    <li>Personalizarea anumitor setări precum: limba în care este vizualizat un site, accesarea preferințelor vechi prin accesarea butonului ‘’înainte’’ și “înapoi’’.</li>
                    <li>Cookie-urile oferă deținătorilor de site-uri un feedback valoros asupra modului cum sunt utilizate site-urile lor de către utilizatori, astfel încât să le poată face și mai eficiente și mai accesibile pentru utilizatori.</li>
                    <li>Permit aplicațiilor multimedia sau de alt tip de pe alte site-uri să fie incluse într-un anumit site pentru a crea o experiență de navigare mai valoroasă, mai utilă și mai placută.</li>
                </ul>
                <Typography><strong>Ce este un “cookie”?</strong></Typography>
                <Typography>
                    Un “Internet Cookie” (termen cunoscut și ca&nbsp;<em>“browser cookie</em>” sau “<em>HTTP cookie</em>” sau pur și simplu“<em>cookie</em>” ) este un fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe
                    computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se accesează Internetul.
                </Typography>
                <Typography>
                    Cookie-ul este instalat prin solicitara emisă de către un web-server unui browser (<em>ex: Internet Explorer, Chrome</em>) și este complet “<strong>pasiv</strong>” (
                    <em>nu conține programe software, viruși sau spyware și nu poate accesa informațiile de pe hard drive-ul utilizatorului</em>).
                </Typography>
                <Typography>
                    Un cookie este format din 2 părți: numele și conținutul sau valoarea cookie-ului. Mai mult, durata de existență a unui cookie este determinată; tehnic, doar webserverul care a trimis cookie-ul îl poate accesa din nou în momentul în
                    care un utilizator se întoarce pe website-ul asociat webserverului respectiv.
                </Typography>
                <br/>
                <Typography><strong>Cookie-urile în sine nu solicită informații cu caracter personal pentru a putea fi utilizate și, în cele mai multe cazuri, nu identifică personal utilizatorii de internet.</strong></Typography>
                <Typography>Exista 2 categorii mari de cookie-uri:</Typography>
                <ol>
                    <li>
                        <strong>Cookie-uri de sesiune</strong>– acestea sunt stocate temporar în folderul&nbsp;de cookie-uri al browserului web pentru ca acesta să le memoreze până când utilizatorul iese de pe website-ul respectiv sau închide fereastra
                        browserului ( ex: în momentul logării/delogării pe un cont de webmail sau pe rețele de socializare).
                    </li>
                    <li>
                        <strong>Cookie-uri persistente</strong>– acestea sunt stocate pe hard-drive-ul unui computer sau echipament (și, în general, depinde de durata de viață prestabilită pentru cookie). Cookie-urile persistente le&nbsp;includ și pe
                        cele plasate de un alt website decât cel pe care îl vizitează utilizatorul la momentul respectiv – cunoscute sub numele de ‘<em>third party cookies</em>’ (cookie-uri plasate de terți) – care pot fi folosite în mod anonim pentru
                        a memora interesele unui utilizator, astfel încât să fie livrată publicitate cât mai relevantă pentru utilizatori.<strong>&nbsp;</strong>
                    </li>
                </ol>
                <Typography><strong>Care sunt avantajele cookie-urilor?</strong></Typography>
                <Typography>
                    Un cookie conține informații care fac legătura între un web-browser (utilizatorul) și un web-server anume (website-ul). Dacă un browser accesează acel web-server din nou, acesta poate citi informația deja stocată și reacționa în
                    consecință.<strong>&nbsp;Cookie-urile asigură userilor o experiență plăcută de navigare și susțin eforturile multor websiteuri pentru a oferi servicii confortabile utilizatorilor</strong>: ex –&nbsp;
                    <em>preferințele în materie de confidențialitate online, opțiunile privind limba site-ului, coșuri de cumpărături sau publicitate relevantă.</em>
                </Typography>
                <Typography><strong>&nbsp;</strong></Typography>
                <Typography><strong>Care este durata de viață a unui cookie?</strong></Typography>
                <Typography>
                    Cookie-urile sunt administrate de webservere. Durata de viață a unui cookie poate varia semnificativ, depinzând de scopul pentru care este plasat. Unele cookie-uri sunt folosite exclusiv pentru o singura sesiune (
                    <em>session cookies</em>) și nu mai sunt reținute odată ce utilizatorul a părăsit website-ul, iar unele cookie-uri sunt reținute și refolosite de fiecare dată când utilizatorul revine pe acel website (
                    <em>cookie-uri permanente</em>). Cu toate aceste, cookie-urile pot fi șterse de un utilizator în orice moment prin intermediul setărilor browserului.
                </Typography>
                <br/>
                <Typography><strong>Ce sunt&nbsp;cookie-urile plasate de terți</strong>?</Typography>
                <Typography>
                    Anumite secțiuni de conținut de pe unele site-uri pot fi furnizate prin intermediul unor terțe părți/furnizori (ex:&nbsp;<em>news box, un video sau o reclamă</em>). Aceste terțe părțti pot plasa de asemenea cookie-uri prin
                    intermediul site-ului și ele se numesc “<em>third party cookies</em>” pentru că nu sunt plasate de proprietarul website-ului respectiv. Furnizorii terți trebuie să respecte, de asemenea, legea în vigoare și politicile de
                    confidențialitate ale deținătorului site-ului.<strong>&nbsp;</strong>
                </Typography>
                <br/>
                <span><strong>Cum sunt folosite cookie-urile de către un site.</strong></span>
                <br/>
                <Typography>O vizită pe un website poate plasa cookie-uri pentru:</Typography>
                <ul>
                    <li>creșterea performanței website-ului;</li>
                    <li>o analiză a vizitatorilor;</li>
                    <li>geotargetting;</li>
                    <li>înregistrarea utilizatorilor.<strong>&nbsp;</strong></li>
                </ul>
                <Typography><strong>Cookie-uri de performanță</strong></Typography>
                <Typography>Acest tip de cookie reține preferințele utilizatorului pe acest site, așa încât nu mai este nevoie de setarea lor la fiecare vizitare a site-ului.</Typography>
                <Typography>Exemple: setările volumului pentru video player, viteza de video streaming cu care este compatibil browserul.</Typography>
                <br/>
                <Typography><strong>Cookie-uri pentru analiza vizitatorilor</strong></Typography>
                <Typography>
                    De fiecare dată când un utilizator vizitează un&nbsp;site, softul de analytics furnizat de o terță parte generează un cookie de analiză a utilizatorului. Acest cookie spune dacă ați mai vizitat acest site până acum. Browserul va
                    semnala dacă aveți acest cookie, iar dacă nu, se va&nbsp;genera unul. Acesta permite monitorizarea utilizatorilor unici care vizitează site-ul și cât de des o fac.
                </Typography>
                <br/>
                <Typography>
                    Atat timp cât vizitatorul nu este înregistrat pe &nbsp;site, acest cookie nu poate fi folosit pentru a identifica persoanele fizice, ele sunt folosite doar în scop statistic. Dacă este înregistrat se pot&nbsp;cunoaște, de asemenea,
                    detaliile furnizate, cum ar fi adresa de e-mail și username-ul – acestea fiind supuse confidențialității, conform prevederilor legislației în vigoare cu privire la protejarea datelor cu caracter personal.
                </Typography>
                <br/>
                <Typography><strong>Cookie-uri pentru geotargetting</strong></Typography>
                <Typography>
                    Aceste cookie-uri sunt utilizate de către un soft care stabilește din ce țară proveniți. Este complet anonim și este folosit doar pentru a targeta conținutul – chiar și atunci când vizitatorul este&nbsp;pe pagina &nbsp;în limba
                    română sau în altă limbă, se va primi aceeași reclamă.
                </Typography>
                <br/>
                <Typography><strong>Cookie-uri pentru înregistrare</strong></Typography>
                <Typography>
                    Atunci când vă înregistrați pe un&nbsp;site, se generează un cookie care anunță dacă sunteți înregistrat sau nu. Serverele folosesc aceste cookie-uri pentru a &nbsp;arăta contul cu care sunteți îngregistrat și dacă aveți permisiunea
                    pentru un serviciu anume. De asemenea, se permite să se asocieze orice comentariu postat pe site cu username-ul dvs. Dacă nu ați selectat “pastrează-mă înregistrat”, acest cookie se va șterge automat când veți închide browserul sau
                    calculatorul.
                </Typography>
                <br/>
                <Typography><strong>Alte cookie-uri ale terțelor părți</strong></Typography>
                <Typography>Pe unele pagini, terții pot seta propriile cookie-uri anonime, în scopul de a urmări succesul unei aplicații sau pentru a customiza o aplicație.</Typography>
                <Typography>De exemplu, când distribuiți (<em>share</em>) un articol folosind butonul pentru rețelele sociale aflat pe un site, acea rețea socială va înregistra activitatea dvs.</Typography>
                <br/>
                <Typography><strong></strong><strong>Ce tip de informații sunt stocate și accesate prin intermediul cookie-urilor?</strong></Typography>
                <Typography>Cookie-urile păstrează informații într-un fișier text de mici dimensiuni care permit unui website să recunoască un browser. Webserverul va recunoaște browserul până când cookie-ul expiră sau este șters.</Typography>
                <Typography>
                    Cookie-ul stochează informații importante care îmbunătățesc experiența de navigare pe Internet ( ex:&nbsp;
                    <em>setările limbii în care se dorește accesarea unui site; păstrarea unui user logat în contul de webmail; securitatea online banking; păstrarea produselor în coșul de cumpărături</em>)
                </Typography>
                <br/>
                <Typography><strong>De ce sunt cookie-urile importante pentru Internet?</strong></Typography>
                <Typography>
                    Cookie-urile reprezintă punctul central al funcționării eficiente a Internetului, ajutând la generarea unei experiențe de navigare prietenoase și adaptată preferințelor și intereselor fiecărui utilizator. Refuzarea sau dezactivarea
                    cookie-urilor poate face unele site-uri imposibil de folosit.
                </Typography>
                <Typography>Exemple de întrebuințări importante ale cookie-urilor (care nu necesită autentificarea unui utilizator prin intermediul unui cont):</Typography>
                <ul>
                    <li>Conținut și servicii adaptate preferințelor utilizatorului – categorii de știri, vreme, sport, hărți, servicii publice și guvernamentale, site-uri de entertainment și servicii de travel.</li>
                    <li>Oferte adaptate pe interesele utilizatorilor – reținerea parolelor, preferințele de limbă ( ex:<em>afișarea rezultatelor căutarilor în limba Română</em>).</li>
                    <li>Reținerea filtrelor de protecție a copiilor privind conținutul pe Internet (<em>opțiuni family mode, funcții de safe search</em>).</li>
                    <li>
                        Măsurare, optimizare și caracteristicile de analytics – cum ar fi confirmarea unui anumit nivel de trafic pe un website, ce tip de conținut este vizualizat și modul cum un utilizator ajunge pe un website (ex:&nbsp;
                        <em>prin motoare de căutare, direct, din alte website-uri</em>). Website-urile derulează aceste analize a utilizarii lor pentru a îmbunătăți site-urile în beneficiul userilor.
                    </li>
                </ul>
                <Typography><strong>Securitate si probleme legate de confidențialitate</strong></Typography>
                <Typography>
                    <strong>Cookie-urile NU sunt viruși</strong>! Ele folosesc formate tip plain text. Nu sunt alcătuite din bucăți de cod, așa că nu pot fi executate, nici nu se pot auto-executa. In consecință, nu se pot duplica sau&nbsp;replica pe
                    alte rețele pentru a se rula sau replica din nou. Deoarece nu pot îndeplini aceste funcții, nu pot fi considerate viruși.
                </Typography>
                <Typography>
                    Cookie-urile pot fi totuși folosite pentru scopuri negative. Deoarece stocheaza informații despre preferințele și istoricul de navigare al utilizatorilor, atât pe un anume site cât și pe mai multe alte siteuri, cookie-urile pot fi
                    folosite ca o formă de Spyware. Multe produse anti-spyware sunt conștiente de acest fapt și in mod constant marchează cookie-urile pentru a fi șterse în cadrul procedurilor de ștergere/scanare anti-virus/anti-spyware.
                </Typography>
                <Typography>In general browserele au integrate setari de confidențialitate care furnizează diferite nivele de acceptare a cookie-urilor, perioada de valabilitate și ștergere automată dupa ce utilizatorul a vizitat un anumit site.</Typography>
                <Typography><strong>Sfaturi pentru o navigare sigură și responsabilă, bazatăa pe cookies.</strong></Typography>
                <ul>
                    <li>Particularizați-vă setările browserului în ceea ce privește cookie-urile pentru a reflecta un nivel confortabil pentru voi al securității utilizării cookie-urilor.</li>
                    <li>
                        Dacă împărțiți accesul la calculator, puteți lua în considerare setarea browserului pentru a șterge datele individuale de navigare de fiecare dată când închideți browserul. Aceasta este o variantă de a accesa site-urile care
                        plasează cookie-uri și de a șterge orice informație de vizitare la închiderea sesiunii navigare.
                    </li>
                    <li>Instalați-vă și actualizați-vă constant aplicații antispyware.</li>
                    <li>
                        Multe dintre aplicațiile de detectare și prevenire a spyware-ului includ detectarea atacurilor pe site-uri. Astfel, impiedică browserul de la a accesa website-uri care ar putea sp exploateze vulnerabilitățile browserului sau să
                        descarce software periculos.
                    </li>
                </ul>
                <Typography><strong>Cum pot opri cookie-urile?</strong></Typography>
                <Typography><strong>Dezactivarea si refuzul de a primi cookie-uri pot face anumite site-uri impracticabile sau dificil de vizitat și folosit.</strong></Typography>
                <Typography>
                    Este posibilă setarea din browser pentru ca aceste cookie-uri să nu mai fie acceptate sau se poate seta browserul să accepte cookie-uri de la un site anume. Dar, de exemplu, dacă un vizitator nu&nbsp;este înregistat folosind
                    cookie-urile, nu va putea lăsa comentarii.
                </Typography>
                <Typography>
                    Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste setări se găsesc de regulă în meniul&nbsp;<strong>Opțiuni&nbsp;/Settings</strong>&nbsp;sau în meniul&nbsp;
                    <strong>Preferințe / Favorites</strong>&nbsp;al browserului.
                </Typography>

            </Container>

            <Footer />
            <BottomBar />
        </div>
    );
};

export default PoliticaCookies;