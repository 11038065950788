import React, {useEffect} from 'react';
import Footer from "../components/Footer/Footer";
import InfoBar from "../components/InfoBar/InfoBar";
import Navigation from "../components/Navigation/Navigation";
import BottomBar from "../components/BottomBar/BottomBar";
import {Container, Typography} from "@mui/material";


const PoliticaDeConfidentialitate = () => {

        useEffect(() => {
                window.scrollTo(0, 0)
        }, [])

        return (
        <div>
            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0" cart={true}/>

            <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "50px"}}>
                <br/>
                <Typography variant="h5" component="div" style={{textAlign: "center"}}>
                    Politica de confidentialitate
                </Typography>
                <br/>
                <br/>
                <Typography> Respectăm dispozițiile legale privitoare la protecția datelor cu caracter personal și punem în aplicare măsuri tehnice și organizatorice de protejare a tuturor operațiunilor privitoare în mod direct sau indirect la datele cu caracter personal, care previn prelucrările neautorizate sau ilegale, precum și pierderile sau distrugerile accidentale sau ilegale.</Typography>
                <br/>
                <Typography> In scopul procesarii mesajelor si cererilor si a onorarii acestora, BLK TRADE MARKET SRL colecteaza datele personale ale clientilor, respectiv numele, prenumele, adresa dumneavoastra, numarul de telefon si adresa de e-mail, in conformitate cu prevederile Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului European din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date.</Typography>
                <br/>
                <Typography>Prelucrăm numele, prenumele, adresa, numărul dumneavoastră de telefon și adresa dumneavoastră de e-mail, pentru comunicarea cu dumneavoastră în vederea executării obligațiilor contractuale si in vederea raspunderii solicitarilor/mesajelor transmise prin intermediul site-ului.</Typography>
                <br/>
                <Typography> Prelucrăm informațiile cu privire la vârsta dumneavoastră pentru a ne asigura că v-ați dat consimțământul în mod valabil cu privire la încheierea contractului și că aveți vârsta cerută de lege pentru asumarea unor obligații în nume propriu.</Typography>
                <br/>
                <Typography> Toate aceste date vor fi păstrate pe durata ținerii evidențelor financiar-contabile și fiscale impuse de legislația în materie, dar nu mai mult de 5 ani.</Typography>
                <br/>
                <Typography> Nu utilizăm datele cu caracter personal pentru prelucrare automatizată și nici pentru realizarea de profile. Nu luăm niciodată decizii automate cu privire la dumneavoastră. Utilizăm mijloace tehnice pentru stocarea datelor în condiții de securitate. Nu prelucrăm date în scopuri secundare incompatibile cu scopurile pentru care le-am colectat.</Typography>
                <br/>
                <Typography> Respectăm și asigurăm în mod strict confidențialitatea datelor prelucrate. Nu dezvăluim datele decât pentru realizarea intereselor dumneavoastră sau pentru îndeplinirea obligațiilor legale. Confidențialitatea este pentru noi o valoare esențială.</Typography>
                <br/>
                <Typography> De asemenea, putem furniza datele cu caracter personal ale dumneavoastră și altor companii cu care ne aflăm in relatii de parteneriat, dar numai in temeiul unui angajament de confidentialitate din partea acestora, prin care garanteaza ca aceste date sunt pastrate in siguranta si ca furnizarea acestor informatii personale se face conform legislatiei in vigoare, dupa cum urmeaza: furnizorilor de servicii de curierat, furnizorilor de servicii de marketing, furnizorilor de servicii de plata/bancare, telemarketing sau alte servicii,  furnizate de companii cu care putem dezvolta programe comune de ofertare pe piata a  Bunurilor si Serviciilor noastre, asigurători etc.</Typography>
                <br/>
                <Typography> In vederea plasarii unei solicitari, clientul va furniza datele si informatiile solicitate in formularul on-line de trimitere mesaj, de plasare a comenzii sau prin telefon, refuzul comunicarii informatiilor solicitate impiedicand vanzatorul sa inregistreze si sa onoreze comanda efectuata. </Typography>
                <br/>
                <Typography> Proprietarul, precum şi operatorul acestui website nu sunt răspunzători pentru erorile clientilor in introducerea acestor date sau de eventuale date false furnizate de acestia. Clientul are obligatia de a furniza date corecte si conforme cu realitatea, purtand intreaga raspundere pentru incorectitudinea informatiilor transmise vanzatorului.</Typography>
                <br/>
                <Typography> Datele personale nu vor fi vândute, transferate sau distribuite sub nici o formă unor terţe părţi ori în alte țări, cu excepţia cazului in care sunt solicitate de autorităţile publice competente, in conformitate cu prevederile Regulamentului (UE) 2016/679.</Typography>
                <br/>
                <Typography> Recomandăm tuturor utilizatorilor acestui website să îşi ia toate măsurile de siguranţă atunci când oferă date personale online şi să verifice periodic starea şi acurateţea acestor date. Utilizatorii sunt răspunzători în mod direct de modul în care îşi protejează datele de logare având în vedere că poate suporta consecinţele unei utilizări neautorizate.</Typography>
                <br/>
                <Typography variant="h6" fontWeight="bold">Drepturile dumneavostră</Typography>
                <br/>
                <Typography>Potrivit Regulamentului (UE) 2016/679, dumneavoastră beneficiați de dreptul de acces si de interventie asupra datelor personale furnizate. In acest sens, aveți dreptul de a obține din partea noastră o confirmare că se prelucrează sau nu date cu caracter personal care vă privesc și, în caz afirmativ, aveți acces la datele respective, precum și la următoarele informații:</Typography>
                <uL>
                    <li>Scopurile prelucrării</li>
                    <li>Categoriile de date cu caracter personal vizate</li>
                    <li>Destinatarii sau categoriile de destinatari cărora datele cu caracter personal le-au fost sau urmează să le fie divulgate;</li>
                    <li>Acolo unde este posibil, perioada pentru care se preconizează că vor fi stocate datele cu caracter personal sau, dacă acest lucru nu este posibil, criteriile utilizate pentru a stabili această perioadă.</li>
                </uL>
                <br/>
                <Typography>La cerere, noi vă vom furniza o copie a datelor cu caracter personal care fac obiectul prelucrării. Pentru orice alte copii solicitate, vă vom percepe o taxă rezonabilă, bazată pe costurile administrative. În cazul în care ne veți adresa cererea în format electronic și cu excepția cazului în care solicitați un format material, informațiile vor fi furnizate într-un format electronic utilizat în mod curent (word, pdf etc.).</Typography>

                <br/>
                <Typography>De asemenea, clientii au dreptul la rectificarea, modificarea și actualizarea datelor prelucrate. Astfel, aveți dreptul de a obține de la noi, fără întârzieri nejustificate, rectificarea datelor cu caracter personal inexacte care vă privesc. Ținându-se seama de scopurile în care au fost prelucrate datele, aveți dreptul de a obține completarea datelor cu caracter personal care sunt incomplete, inclusiv prin furnizarea unei declarații suplimentare.</Typography>
                <br/>
                <Typography>Aveți dreptul de a obține din partea noastră ștergerea datelor cu caracter personal care vă privesc, fără întârzieri nejustificate, iar noi avem obligația de a șterge datele cu caracter personal fără întârzieri nejustificate în cazul în care există unul dintre următoarele motive:</Typography>
                <br/>
                <ul>
                    <li>Datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate;</li>
                    <li>Vă veți retrage consimțământul pe baza căruia are loc prelucrarea și nu există niciun alt temei juridic pentru prelucrare;</li>
                    <li>Vă veți opune prelucrării și nu există motive legitime care să prevaleze în ceea ce privește prelucrarea;</li>
                    <li>Datele cu caracter personal au fost prelucrate ilegal;</li>
                    <li>Datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care ne revine în temeiul dreptului Uniunii Europene sau al legilor din România;</li>
                </ul>

                <br/>

                <Typography> În cazul în care vom comunica datele dumneavoastră societăților de curierat sau celorlalți parteneri, iar dumneavoastră veți solicita ștergerea acestora, vom lua toate măsurile rezonabile pentru a informa partenerii care prelucrează datele că ați solicitat ștergerea oricăror linkuri către datele respective sau a oricăror copii sau reproduceri ale acestor date cu caracter personal.</Typography>
                <br/>
                <Typography>Datele nu vor fi șterse dacă:</Typography>
                <br/>
                <ul>
                    <li>Prelucrarea este necesară pentru respectarea unei obligații legale care prevede prelucrarea în temeiul dreptului Uniunii sau al legilor din România sau pentru îndeplinirea unei sarcini executate în interes public;</li>
                    <li>Din motive de interes public în domeniul sănătății publice;</li>
                    <li>Prelucrarea este necesară pentru constatarea, exercitarea sau apărarea unui drept în instanță.</li>
                </ul>
                <br/>
                <Typography>Aveți dreptul de a obține restricționarea prelucrării în cazul în care se aplică unul din următoarele cazuri:</Typography>
                <br/>
                <ul>
                    <li>Contestați exactitatea datelor, pentru o perioadă care ne permite să verificăm exactitatea datelor prelucrate;</li>
                    <li>Prelucrarea este ilegală, iar dumneavoastră vă veți opune ștergerii datelor cu caracter personal, solicitând în schimb restricționarea utilizării lor;</li>
                    <li>Noi nu mai avem nevoie de datele cu caracter personal în scopul prelucrării, dar dumneavoastră ni le veți solicita pentru constatarea, exercitarea sau apărarea unui drept în instanță.</li>
                </ul>
                <br/>
                <Typography>În cazul în care prelucrarea datelor a fost restricționată în cazurile sus-menționate, astfel de date cu caracter personal pot, cu excepția stocării, să fie prelucrate numai cu consimțământul dumneavoastră sau pentru constatarea, exercitarea sau apărarea unui drept în instanță sau pentru protecția drepturilor unei alte persoane fizice sau juridice sau din motive de interes public important al Uniunii Europene sau al Statului Român.</Typography>
                <br/>
                <Typography>În cazul în care ați obținut restricționarea prelucrării datelor cu caracter personal, veți fi informată de către de noi înainte de ridicarea restricției de prelucrare.</Typography>
                <br/>
                <Typography>Aveți dreptul de a primi datele cu caracter personal care vă privesc și pe care ni le-ați furnizat, într-un format structurat, utilizat în mod curent și care poate fi citit automat. Aveți dreptul de a transmite aceste date altui operator, fără obstacole din partea noastră, direct de la noi către un alt operator, acolo unde acest lucru este fezabil din punct de vedere tehnic. Exercitarea dreptului menționat anterior nu aduce atingere dreptului dumneavoastră la ștergerea datelor.</Typography>
                <br/>
                <Typography>Orice client are, de asemenea, dreptul de a se opune, în mod gratuit si fara nici o justificare, la prelucrarile datelor sale personale în scopuri de marketing direct.</Typography>
                <br/>
                <Typography>Pentru exercitarea tuturor drepturilor menționate anterior, va puteti adresa oricand cu o cerere scrisa la sediul nostru din IASI, BDUL. TUDOR VLADIMIRESCU, NR. 15, BL. P12, SC. D, ET. 2, AP. 2, JUDETUL IASI. De asemenea, va este recunoscut dreptul de a va adresa justitiei.</Typography>
                <br/>
                <Typography>Datele dumneavoastra nu vor fi transferate într-o alta tara.</Typography>
                <br/>
                <Typography>Prin completarea datelor in formularele de trimitere mesaj, creare de cont si/sau de comanda, dumneavoastra, utilizatorul/cumparatorul declarati si acceptati in mod expres ca datele dumneavoastre personale sa fie incluse in baza de date a BRACLEANING.RO si vă dați acordul expres si neechivoc ca toate aceste date personale sa fie stocate, utilizate si prelucrate in scopul executării comenzilor efectuate sau raspunderii mesajelor si solicitarilor transmise.</Typography>
                <br/>
                <Typography>Informatiile cumparatorului cu caracter personal pot fi furnizate si catre Parchetul General, Politie, instantele judecatoresti si alte organe abilitate ale statului, in baza si in limitele prevederilor legale si ca urmare a unor cereri expres formulate.</Typography>
                <br/>
                <Typography>Beneficiați de dreptul de acces, intervenție, rectificare și portare cu privire la datele pe care ni le furnizați, să limitați prelucrarea realizată de noi și chiar să solicitați ștergerea datelor. Menționăm că intervenția asupra datelor pe care ni le-ați furnizat poate fi de natură să împiedice executarea contractului încheiat între dumneavoastră și noi. În acest caz, noi vom fi exonerați de răspundere. Dacă sunteți nemulțumit, aveți posibilitatea să vă adresați și să depuneți plângere împotriva noastră în fața Autorității Nationale de Supraveghere a Prelucrarii Datelor cu Caracter Personal, precum și în fața intsanțelor de judecată competente.</Typography>
                <br/>
                <br/>
            </Container>

            <Footer />
            <BottomBar />
        </div>
    );
};

export default PoliticaDeConfidentialitate;