import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {Button, Menu, MenuItem} from "@mui/material";
import {useCart} from "react-use-cart";

const ITEM_HEIGHT = 48;

const options = [1,2,3,4,5,6,7,8,9,10];

const ShoppingCartList = () => {

    const {
        items,
        updateItemQuantity,
        removeItem,
    } = useCart();

    let myRef = React.createRef();

    const [anchorEl, setAnchorEl] = useState('');
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
            {items.map((item, i, row) => (
                <div key={item.id} id={item.id}>
                    <ListItem
                    alignItems="flex-start"
                    secondaryAction={
                    <React.Fragment>

                    <IconButton onClick={() => removeItem(item.id)} edge="end" aria-label="delete" style={{ marginTop: "8px"}}>
                    <DeleteIcon  />
                    </IconButton>
                    </React.Fragment>
                }
                    >
                    <ListItemAvatar>
                    <Avatar alt="Cindy Baker" src={item.thumbnail} />
                    </ListItemAvatar>
                    <ListItemText style={{ color: "black"}}
                    primary={<span style={{ fontSize: "1.7vh", fontWeight: "bold"}}>{item.name}</span>}
                    secondary={
                    <React.Fragment>
                        <Button onClick={handleClick} style={{ textTransform: "none", marginLeft: "-6px", fontSize: "1.7vh"}}> { item.price  +" Ron"} &#9679; {"Cantitate: " + item.quantity } </Button>

                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} ref={myRef} onClick={() => {
                                    setAnchorEl(null);
                                    updateItemQuantity(item.id, option)
                                    debugger;
                                }}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>

                    </React.Fragment>
                }
                    />

                    </ListItem>
                    { i + 1 !== row.length ?
                        <Divider variant="inset" component="li"/> : ""
                    }
                </div>
            ))}
        </List>
    );
};

export default ShoppingCartList;