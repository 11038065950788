import WooCommerceAPI from 'react-native-woocommerce-api';


const wordpressApi = new WooCommerceAPI({
    url: "https://tabacshop.ro/wordpress",
    consumerKey: "ck_069d75fac9e6dd987c62e41e9495044b71c59c51",
    consumerSecret: "cs_daa3c4265880ae6047d6a4be9215ab4f1de802da",
    version: "wc/v3"
});

export { wordpressApi }