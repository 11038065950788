import React, {Fragment} from 'react';

import "./BottomBar.css";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {Paper} from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useMediaQuery from "@mui/material/useMediaQuery";
import Transport from "../Transport/Transport";
import ProduseBottomBar from "./ProduseBottomBar/ProduseBottomBar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom';

const BottomBar = ({mobileProduct}) => {

    const matches = useMediaQuery('(min-width:820px)');

    const [open, setOpen] = React.useState(false);
    const [product, setProduct] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseProduct = () => {
        setProduct(false);
    };

    const handleClickOpenProduct = () => {
        setProduct(true);
    }

    const handleClickOpen = (val) => {
        setOpen(true);
        setValue(val);
    };

    const navigate = useNavigate();

    return (
        <Fragment>
            <Paper
                style={{ display: matches === true ? 'none' : 'inherit' }}
                sx={{ width: "100%", zIndex: "999", position: 'fixed', bottom: 0, left: 0, right: 0 }}
                elevation={3}
            >
                <BottomNavigation
                    showLabels
                    onChange={(event, newValue) => {

                    }}
                >
                    {mobileProduct ? <BottomNavigationAction label="Inapoi" icon={<ArrowBackIcon/>}
                                                             onClick={() => navigate(-1)}/> :
                        <BottomNavigationAction label="Produse" icon={<ShoppingBagIcon/>}
                                                onClick={() => handleClickOpenProduct()}/>
                    }
                    <BottomNavigationAction label="Contact" icon={<ChatBubbleIcon />} onClick={() => handleClickOpen(0)} />
                    <BottomNavigationAction label="Whatsapp" icon={<WhatsAppIcon />} href="https://api.whatsapp.com/send?phone=40763720000"/>
                </BottomNavigation>
            </Paper>
            <Transport open={open} handleClose={handleClose} value={value} setValue={setValue}/>
            <ProduseBottomBar open={product} handleClose={handleCloseProduct} />
        </Fragment>
    );
};

export default BottomBar;