import React, {Fragment} from 'react';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import "./Fab.css";
import {Fab} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const FabButton = () => {

    const matches = useMediaQuery('(min-width:820px)');

    return (
        <Fragment>
        <a href="https://api.whatsapp.com/send?phone=40763720000">
            <Fab className="fab" aria-label="add" style={{ display: matches === true ? 'inline-flex' : 'none' }}>
                <WhatsAppIcon style={{ color: "white"}} />
            </Fab>
        </a>
        </Fragment>
    );
};

export default FabButton;