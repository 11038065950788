import React from 'react';
import {Button} from "@mui/material";

import "./AddToCart.css";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {useCart} from "react-use-cart";

const AddToCart = ({ id, name, price, imageSrc }) => {

    const { addItem } = useCart();

    let ext = imageSrc.split('.').pop();
    let thumbnail =  imageSrc.replace(/\.[^/.]+$/, "") + "-100x100." + ext;

    return (
        <Button startIcon={<ShoppingCartIcon />}
                size="small"
                variant="outlined"
                className="product__button"
                style={{ width: "100%", height: "40px"}}
                onClick={(e) => {
                    e.preventDefault();
                    addItem({ id, name, price, thumbnail })
                }}>Adauga in cos</Button>
    );
};

export default AddToCart;