import React from 'react';

import "./Transport.css";
import {
    Button,
    Checkbox,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CloseIcon from "@mui/icons-material/Close";
import {styled, useTheme} from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import useMediaQuery from '@mui/material/useMediaQuery';
import {Link} from "react-router-dom";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, onChange, value, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{color: "#3A3A3A"}}>

            <Tabs value={value} onChange={onChange} aria-label="basic tabs example">
                <Tab icon={<ChatBubbleIcon/>}  iconPosition="start" label="Contact" className="infobar__tab" {...a11yProps(1)} />
                <Tab icon={<LocalShippingIcon/>} iconPosition="start" label="Transport" className="infobar__tab" {...a11yProps(0)} />
            </Tabs>

            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 18,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
)
{
    return <Slide direction="down" ref={ref} {...props} />;
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const Transport = ({open, handleClose, value, setValue}) => {

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <BootstrapDialog
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} onChange={handleChange} value={value}/>

            <DialogContent dividers>

                <TabPanel value={value} index={1}>
                    <Typography gutterBottom>
                        Comenzile primite si confirmate pana la ora 12.00 vor fi expediate in aceasi zi, exceptie sambata, duminica si
                        zilele de sarbatoare sau cand se mentioneaza diferit la telefon/e-mail.
                    </Typography>
                    <br/>
                    <Typography gutterBottom style={{ fontWeight: "bold"}}>
                        Transportul se efectueaza prin curier rapid oriunde in tara
                    </Typography>
                    <Typography>
                        <ul>
                            <li>durata de livrare variaza intre 24 -72 ore lucratoare.</li>
                            <li>coletul este adus de catre curier la adresa mentionata in comanda plasata pe site/telefonic</li>
                            <li>Tariful unic –  20 ron*</li>
                            <li>Plata se face ramburs la ridicarea coletului</li>
                        </ul>
                    </Typography>
                    <Typography>Pretul transportului este suportat in intregime de catre cumparator si apare pe factura fiscala</Typography>
                    <br/>
                    <Typography><span style={{ fontWeight: "bold"}}>Nota</span> – Ne rezervam dreptul  de a nu trimite comenzile in cazul in care acestea nu au adresa completa,
                        nu au notat numarul de telefon activ, nu confirma telefonic comanda facuta, exista comenzi neridicate anterior,
                        nu se respecta conditiile minime cerute sau oricare alta situatie considerata a necesita anularea acestora.
                    </Typography>
                    <br/>
                    <Divider />
                    <br/>
                    <Typography>
                        *Tarif transport 20 ron – primul kg.
                    </Typography>
                    <br/>
                </TabPanel>
                {/*Contact*/}
                <TabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                        <Paper
                            sx={{
                                p: 2,
                                margin: 'auto',
                                flexGrow: 1,
                                backgroundColor: "#fafafa",
                                boxShadow: "none"
                            }}
                        >
                            <Grid container spacing={2}>
                        <Grid item sm={6} style={{ textAlign: "center"}}>
                            <Button variant="text" startIcon={<LocalPhoneIcon />} style={{ color: "black"}}>
                                +40 763 720 000
                            </Button>
                        </Grid>
                        <Grid item sm={6} style={{ textAlign: "center"}}>
                            <Button variant="text" startIcon={<EmailIcon />} style={{ color: "black", textTransform: "none"}}>
                                blktrademarket@gmail.com
                            </Button>
                        </Grid>
                            </Grid>
                        </Paper>

                        <Grid item xs={12} >

                            <Typography>
                                Suntem alaturi de tine si acordam importanta maxima opiniei tale. Raspundem cat mai repede solicitarilor pe care ni le trimiti, acceptam cu drag sugestii de imbunatatire sau sesizari. Iti multumim !
                                <br/><br/>
                                <b>PRODUSELE SE EXPEDIAZA DOAR PRIN CURIER.</b>
                                <br/>
                                Pentru comenzi telefonice, va rugam sa ne contactati:
                                <br/>
                                Luni – Vineri intre: 09.00 – 18.00
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <TextField fullWidth id="filled-basic" label="Nume" variant="outlined" />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField fullWidth id="filled-basic" label="Telefon" variant="outlined" />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField fullWidth id="filled-basic" label="Email" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                id="standard-multiline-static"
                                label="Mesaj"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Grid item xs={1} style={{  display: "inline-block"}} >
                            <Checkbox  style={{marginLeft: "-10px"}}  />
                            </Grid>
                            <Grid item xs={11} style={{  display: "inline-block"}}>
                            <Typography>Am citit si sunt de acord cu <Link to="/politica-de-confidentialitate">Politica de confidențialitate</Link> și <Link to="/politica-cookies">Politica cookies</Link>.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Button fullWidth disableElevation variant="contained">Trimite mesajul</Button>
                        </Grid>
                    </Grid>
                </TabPanel>


            </DialogContent>

        </BootstrapDialog>
    );
};

export default Transport;