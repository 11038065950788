import React, {useEffect} from 'react';
import InfoBar from "../components/InfoBar/InfoBar";
import Navigation from "../components/Navigation/Navigation";
import Carousel from "../components/Carousel/Carousel";
import ProductsGrid from "../components/ProductsGrid/ProductsGrid";
import Despre from "../components/Despre/Despre";
import Footer from "../components/Footer/Footer";
import BottomBar from "../components/BottomBar/BottomBar";
import FabButton from "../components/Fab/Fab";
import {getProducts} from "../requests/Products";
import {ScrollingProvider} from 'react-scroll-section';
import AgeVerification from "../components/AgeVerification/AgeVerification";
import Snowfall from 'react-snowfall'

const Home = ({ addCartItem }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        getProducts();
    }, [])

    return (
        <div>
            <AgeVerification/>
            <InfoBar/>
            <Navigation bgType="transparent" topType="15px" cart={true}/>
            <ScrollingProvider offset={300} debounceDelay={0}>
            <Carousel/>
            <ProductsGrid addCartItem={addCartItem} />
            <BottomBar mobileProduct={false}/>
            </ScrollingProvider>
            <Despre/>
            <Footer/>
            <FabButton/>
        </div>
    );
};

export default Home;