import React from 'react';

import "./InfoBar.css";

import ContactButtons from "../ContactButtons/ContactButtons";
import useMediaQuery from "@mui/material/useMediaQuery";


const InfoBar = () => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <div className="infobar" style={{ display: matches === true ? 'inline-flex' : 'none' }}>
            <ContactButtons footer={false}/>
        </div>
    );
};

export default InfoBar;