import React, {Fragment} from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import "./Carousel.css";
import {Button, Typography} from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {useScrollSection} from "react-scroll-section";

const Carousel = () => {

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const h1 = useScrollSection('Tigari Electronice');
    const h7 = useScrollSection('Solutie Umidificat Tutun');
    const h8 = useScrollSection('Aparate Injectat Tutun');

    return (
        <Fragment>
        <AutoplaySlider
            className="carousel"
            bullets={false}
            play={true}
            cancelOnInteraction={false}
            interval={6000}
        >
            {/*<div data-src="https://s13emagst.akamaized.net/layout/ro/images/db//88/131476.jpg" />*/}
            <div style={{ backgroundImage: "linear-gradient(180deg, rgba(20,20,20, 1), rgba(20, 20, 20, 0.2), rgba(255, 255, 255, 0)), url(\"https://tabacshop.ro/wordpress/wp-content/uploads/2022/11/DSC_4834-final.jpg\") ",
                            backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: "#121212"}}>
                <div style={{ maxWidth: "500px", textAlign: "center", padding: "5vh"}}>
                    <div style={{ backgroundColor: "rgba(0,0,0,0.19)",  border: "1px solid white", backdropFilter: "blur(5px)", padding: "2vh", borderRadius: "10px", boxShadow: "box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5)"}}>
                    <Typography variant="h5" style={{ color: "white", fontWeight: "bold", textTransform: "uppercase"}}>Țigări electronice <br/> Aroma King <br/> <span style={{ fontWeight: "100", display: "block", marginTop: "10px", marginBottom: "5px"}}>700 Puffs</span> </Typography>
                    <br/>
                    <Button variant="contained" startIcon={<KeyboardDoubleArrowDownIcon />}
                            onClick={h1.onClick}
                            style={{ color: "white", borderColor: "white"}}>Vezi categoria</Button>
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: "linear-gradient(180deg, rgba(20,20,20, 1), rgba(20, 20, 20, 0.2), rgba(255, 255, 255, 0)), url(\"https://tabacshop.ro/wordpress/wp-content/uploads/2022/11/DSC_4824-final.jpg\") ",
                backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: "#121212"}}>
                <div style={{ maxWidth: "500px", textAlign: "center", padding: "5vh"}}>
                    <div style={{ backgroundColor: "rgba(0,0,0,0.19)", border: "1px solid white", backdropFilter: "blur(5px)", padding: "2vh", borderRadius: "10px", boxShadow: "box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5)"}}>
                        <Typography variant="h5" style={{ color: "white", fontWeight: "bold", textTransform: "uppercase"}}>Powermatic  <br/> <span style={{ fontWeight: "100", display: "block", marginTop: "10px", marginBottom: "5px"}}>Aparate și mașini electrice pentru injectat tutun</span> </Typography>
                        <br/>
                        <Button variant="contained" startIcon={<KeyboardDoubleArrowDownIcon />}
                                onClick={h8.onClick}
                                style={{ color: "white", borderColor: "white"}}>Vezi categoria</Button>
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: "linear-gradient(180deg, rgba(20,20,20, 1), rgba(20, 20, 20, 0.2), rgba(255, 255, 255, 0)), url(\"https://tabacshop.ro/wordpress/wp-content/uploads/2022/11/DSC_4826-final.jpg\") ",
                backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: "#121212"}}>
                <div style={{ maxWidth: "500px", textAlign: "center", padding: "5vh"}}>
                    <div style={{ backgroundColor: "rgba(0,0,0,0.19)", border: "1px solid white", backdropFilter: "blur(5px)", padding: "2vh", borderRadius: "10px", boxShadow: "box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5)"}}>
                        <Typography variant="h5" style={{ color: "white", fontWeight: "bold", textTransform: "uppercase"}}>UWOO <br/> Țigări electronice <br/> <span style={{ fontWeight: "100", display: "block", marginTop: "10px", marginBottom: "5px"}}>1600 Puffs</span> </Typography>
                        <br/>
                        <Button variant="contained" startIcon={<KeyboardDoubleArrowDownIcon />}
                                onClick={h1.onClick}
                                style={{ color: "white", borderColor: "white"}}>Vezi categoria</Button>
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: "linear-gradient(180deg, rgba(20,20,20, 1), rgba(20, 20, 20, 0.2), rgba(255, 255, 255, 0)), url(\"https://tabacshop.ro/wordpress/wp-content/uploads/2022/11/DSC_4823-final-scaled.jpg\") ",
                backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: "#121212"}}>
                <div style={{ maxWidth: "500px", textAlign: "center", padding: "5vh"}}>
                    <div style={{ backgroundColor: "rgba(0,0,0,0.19)", border: "1px solid white", backdropFilter: "blur(5px)", padding: "2vh", borderRadius: "10px", boxShadow: "box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5)"}}>
                        <Typography variant="h5" style={{ color: "white", fontWeight: "bold", textTransform: "uppercase"}}>Smoks Aroma  <br/> <span style={{ fontWeight: "100", display: "block", marginTop: "10px", marginBottom: "5px"}}>Soluții umidificare tutun</span> </Typography>
                        <br/>
                        <Button variant="contained" startIcon={<KeyboardDoubleArrowDownIcon />}
                                onClick={h7.onClick}
                                style={{ color: "white", borderColor: "white"}}>Vezi categoria</Button>
                    </div>
                </div>
            </div>


        </AutoplaySlider>
        </Fragment>
    );
};

export default Carousel;