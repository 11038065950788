import {wordpressApi} from "../config/Config";
import {productsStore} from "../state/State";

const getProducts = () => {

    let data = {per_page: 100, order: "asc"}
    let url = wordpressApi._getUrl("products")
    let auth = wordpressApi._getOAuth().authorize({
        url: url + '?' + wordpressApi.join(data, '&'),
        method: "GET",
    });
    console.log(auth);
    let requestUrl = url + '?' + wordpressApi.join(auth, '&');
    console.log(requestUrl);

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch(requestUrl, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log(JSON.parse(result));
            productsStore.products = JSON.parse(result)
                .sort((a, b) => a.categories[0].name.localeCompare(b.categories[0].name))
                .sort((a, b) => productsStore.menuProducts.indexOf(a.categories[0].name) - productsStore.menuProducts.indexOf(b.categories[0].name))
            ;
            console.log(productsStore.product.length);
        })
        .catch(error => console.log('error', error));

}

const getProduct = (id) => {

    let url = wordpressApi._getUrl("products/" + id)
    let auth = wordpressApi._getOAuth().authorize({
        url: url,
        method: "GET",
    });
    console.log(auth);
    let requestUrl = url + '?' + wordpressApi.join(auth, '&');

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch(requestUrl, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log(JSON.parse(result));
            productsStore.product = JSON.parse(result);
        })
        .catch(error => console.log('error', error));

}

const postOrder = (request, data) => {

    let url = wordpressApi._getUrl("orders")
    let auth = wordpressApi._getOAuth().authorize({
        url: url + '?' + wordpressApi.join(data, '&'),
        method: "POST",
    });
    console.log(auth);
    let requestUrl = url + '?' + wordpressApi.join(auth, '&');
    console.log(requestUrl);

    request["requestUrl"] = requestUrl;
    debugger;
    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        redirect: 'follow'
    };

    fetch(requestUrl, requestOptions)
        .then(response => response.text())
        .then(result => {
            let res = JSON.parse(result);
            console.log(res);
            window.location.href = '/done/'+ res.id;
        })
        .catch(error => console.log('error', error));

}

export { getProducts, getProduct, postOrder }