import React from 'react';
import Navigation from "../components/Navigation/Navigation";
import InfoBar from "../components/InfoBar/InfoBar";

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import "./Done.css";
import {Button, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";

import HomeIcon from '@mui/icons-material/Home';
import {view} from '@risingstack/react-easy-state';
import {useCart} from "react-use-cart";

const Done = () => {

    let { id } = useParams();

    const { emptyCart } = useCart();
    emptyCart();

    return (
        <div>
            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0" cart={false}/>
            <div style={{ height: "calc(100vh - 65px - 46px)", width: "100%", backgroundColor: "rgb(61, 26, 0)"}}>
                <div className="center-1">
                    <CheckCircleOutlinedIcon style={{ color: "#4bff00" +
                            "", fontSize: "20vh"}}/>
                    <Typography variant="h5" style={{ textAlign: "center", color: "white"}}> Comanda a fost plasata cu succes !</Typography>
                    <Typography variant="h6" style={{ textAlign: "center", color: "white"}}> Comanda numarul: {id} </Typography>
                    <Button startIcon={<HomeIcon />} variant="outlined" style={{ color: "white", marginTop: "20px", borderColor: "white"}}>
                        <Link to="/" style={{ textDecoration: "none", width: "100%"}}>
                            Inapoi la magazin
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default view(Done);