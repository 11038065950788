import React, {Fragment} from 'react';
import {Button, Stack} from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Transport from "../Transport/Transport";

const ContactButtons = ({ footer }) => {

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const handleClickOpen = (val) => {
        setOpen(true);
        setValue(val);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Stack  direction={ footer ? "column" : "row"} spacing={1} className={`infobar__buttons ${footer ? "infobar__footer" : "infobar__main"} `}>
                <Button variant="text" startIcon={<LocalPhoneIcon />} >
                    +40 763 720 000
                </Button>
                <Button variant="text" startIcon={<EmailIcon />} >
                    blktrademarket@gmail.com
                </Button>
                <Button variant="text" startIcon={<ChatBubbleIcon />} onClick={() => handleClickOpen(0)}>
                    Contact
                </Button>
                <Button variant="text"  startIcon={<LocalShippingIcon />} onClick={() => handleClickOpen(1)}>
                    Transport
                </Button>
            </Stack>
            <Transport open={open} handleClose={handleClose} value={value} setValue={setValue}/>
        </Fragment>
    );
};

export default ContactButtons;