import React from 'react';

import "./Footer.css";
import {Button, Container, Grid, Stack, Typography} from "@mui/material";
import logo from "../../logo1.png";
import ContactButtons from "../ContactButtons/ContactButtons";
import {Link} from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import GavelIcon from '@mui/icons-material/Gavel';

const Footer = () => {
    return (
        <div className="footer">
            <Container maxWidth="lg">
                <Grid className="footer__container" container spacing={5}>
                    <Grid item md={4}>
                        <img src={logo} alt="Logo" className="logoFooter"  />
                        <Typography variant="body1" style={{color: "white", paddingRight: "70px", paddingTop: "20px", maxWidth: "500px"}}>
                            Garantam calitatea produselor noastre. Suntem importator direct pentru produsele din gama Smoks Aroma, Powermatic si tigari electronice UWOO Tech.
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="h5" component="div" style={{color: "white", marginBottom: "7px"}}>
                            Informații
                        </Typography>
                        <Stack spacing={2} direction="column" className="infobar__footer">
                            <Button variant="text" className="footer__buttons" startIcon={<InfoIcon />} >
                                <Link to="/politica-de-confidentialitate" style={{ textDecoration: "none"}}>
                                    Politica de confidențialitate
                                </Link>
                            </Button>
                            <Button variant="text" className="footer__buttons" startIcon={<InfoIcon />} >
                                <Link to="/politica-cookies" style={{ textDecoration: "none"}}>
                                    Politica cookies
                                </Link>
                            </Button>
                            <Button variant="text" className="footer__buttons" startIcon={<GavelIcon />} >
                                <Link to="/termeni-si-conditii" style={{ textDecoration: "none"}}>
                                    Termeni și condiții
                                </Link>
                            </Button>
                            <Button variant="text" className="footer__buttons" startIcon={<AutorenewIcon />} >
                                <Link to="/returnare-produse" style={{ textDecoration: "none"}}>
                                    Returnare produse
                                </Link>
                            </Button>
                            {/*<ul className="footer__links">*/}
                            {/*    <Link to="/"><li>Acasa</li></Link>*/}
                            {/*    <li>Produse</li>*/}
                            {/*    <li>Despre noi</li>*/}
                            {/*    <Link to="/politica-de-confidentialitate"><li>Politica de confidențialitate</li></Link>*/}
                            {/*    <Link to="/politica-cookies"><li>Politica cookies</li></Link>*/}
                            {/*    <Link to="/termeni-si-conditii"><li>Termeni și condiții</li></Link>*/}
                            {/*    <Link to="/returnare-produse"><li>Returnare produse</li></Link>*/}
                            {/*</ul>*/}
                        </Stack>
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="h5" component="div" style={{color: "white", marginBottom: "7px"}}>
                            Contact
                        </Typography>
                        <ContactButtons footer={true}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;