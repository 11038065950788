import {createTheme, ThemeProvider} from '@mui/material/styles';


import "./App.css";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import PoliticaDeConfidentialitate from "./pages/PoliticaDeConfidentialitate";
import PoliticaCookies from "./pages/PoliticaCookies";
import TermeniSiConditii from "./pages/TermeniSiConditii";
import ReturnareProduse from "./pages/ReturnareProduse";
import Produs from "./pages/Produs";
import PageNotFound from "./pages/PageNotFound";

import {CartProvider} from "react-use-cart";
import Checkout from "./pages/Checkout";
import Done from "./pages/Done";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(59, 25, 0)'
        }
    }
});

function App() {

  return (
    <div className="App">
        <CartProvider>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/produs/:id" element={<Produs />} />
                        <Route path="/politica-de-confidentialitate" element={<PoliticaDeConfidentialitate />} />
                        <Route path="/politica-cookies" element={<PoliticaCookies />} />
                        <Route path="/termeni-si-conditii" element={<TermeniSiConditii />} />
                        <Route path="/returnare-produse" element={<ReturnareProduse />} />
                        <Route path="/checkout" element={<Checkout />} />
                        <Route path="/done/:id" element={<Done />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </CartProvider>
    </div>
  );
}

export default App;
