import React, {useEffect} from 'react';

import "./AgeVerification.css";
import DialogActions from "@mui/material/DialogActions";
import {Button, DialogContent, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {reactLocalStorage} from 'reactjs-localstorage';

const AgeVerification = () => {

    useEffect(() => {
        if (reactLocalStorage.get('consent', false) === false) {
            handleClickOpen();
        } else {
            setOpen(false);
        }
    }, []);

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitleVerification = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other} >
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        reactLocalStorage.set('consent', true);
    };
    const handleRedirect = () => {
        window.location.href = "http://www.google.com";
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title-verification"
            open={open}
        >
            <BootstrapDialogTitleVerification id="customized-dialog-title-verification" >
                Trebuie sa ai <span style={{ color: "green", fontWeight: "bold"}}>cel putin 18 ani</span> pentru a putea cumpara produse din acest magazin
            </BootstrapDialogTitleVerification>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Pentru a continua navigarea pe acest site trebuie sa confirmi ca ai 18 ani si varsta legala pentru a cumpara produse de tutun din tara in care locuiesti.
                </Typography>
                <br/>
                <Typography gutterBottom>
                    “Declar pe propria raspundere ca am varsta de cel putin 18 ani impliniti si ca prin plasarea comenzii nu incalc prevederile legale in vigoare”
                </Typography>
                <br/>
                <Typography gutterBottom>
                    Directiva Consiliului CE 2001/37/CEE<br/>
                    <strong>Tutunul dauneaza grav sanatatii dumneavoastra si a celor din jur.</strong>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleRedirect} color="error" variant="contained" disableElevation={true}>
                    Nu am 18 ani
                </Button>
                <Button autoFocus onClick={handleClose} color="success" variant="contained" disableElevation={true}>
                    Confirm ca am 18 ani
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default AgeVerification;