import React from 'react';

import "./Despre.css";
import {Card, CardContent, Container, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const Despre = () => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <div style={{ backgroundColor: "#27272E", marginBottom: "-60px", marginTop: "80px"}}>
            <Container maxWidth="lg" className="about" style={{ paddingTop: "30px", paddingBottom: "30px", borderRadius: "7px"}}>
                <Card elevation={0} className="about__card" style={{ padding: matches ? "5vh" : "0px"}}>
                    <CardContent className="about__content">
                        <Typography variant="h5" component="div" style={{paddingBottom: "16px", color: "#fff" }}>Despre Noi</Typography>
                        <Typography variant="body1">
                            Oferim clientilor nostri optiunea de a-si realiza propriile tigari
                            folosind diverse produse din tutun si tuburi dupa
                            modelul RYO (roll-your-own) sau MYO (make-your-own).
                        </Typography>
                        <br/>
                        <Typography variant="body1" fontWeight="bold">
                            Economisiti pana la 50% din banii pentru tigari, calitatea
                            celor obtinute astfel fiind net superioare celor din comert !
                        </Typography>
                        <br/>
                        <Typography variant="body1">
                            Pentru  umplerea tutunului in tuburi de tigari se folosesc aparatele de
                            injectate electrice sau manuale acestea din urma fiind ideale pentru incepatori.
                        </Typography>
                        <br/>
                        <Typography variant="body1">
                            Comercializam tutun original de fabrica, ambalat si timbrat !
                            Acesta poate fi tocat, firicele sau poate fi rulat. Pe ambalajele pachetelor de
                            tutun veti gasi si recomandarea producatorului in utilizarea acestuia, gramajul, taria si tipul acestuia.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default Despre;