import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardMedia, Chip, Container, Divider, Grid, Typography} from "@mui/material";

import "./ProductsGrid.css";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import NavigationProducts from "../NavigationProducts/NavigationProducts";
import useMediaQuery from "@mui/material/useMediaQuery";

import {Link} from "react-router-dom";
import AddToCart from "../AddToCart/AddToCart";
import {productsStore} from "../../state/State";
import {view} from '@risingstack/react-easy-state';
import {computeStock} from "../../util/Stock";

import {Section} from 'react-scroll-section';

const nav = (
    <React.Fragment>
        <CardContent style={{ backgroundColor: "rgb(59, 25, 0)"}}>
            <Typography variant="h5" component="div" style={{paddingLeft: "10px", paddingBottom: "2px", marginTop: "7px" , color: "#fff", fontWeight: "lighter"}}>
                <ShoppingBagIcon style={{marginRight: "10px", position: "relative", top: "2px", color: "#fff"}}/>
                Produse
            </Typography>

        </CardContent>
        <CardContent>
            <NavigationProducts/>
        </CardContent>
    </React.Fragment>
);

const card = (id, name, imageSrc, price, stock) => {
    return <React.Fragment>
        <CardMedia
            component="img"
            height="280"
            image={imageSrc}
            alt="green iguana"
            style={{ objectFit: "contain", backgroundColor: "#fff"}}
        />
        <CardContent>
            <Typography component="div" style={{ height: "100px", overflow: "hidden"}}>
                {name}
            </Typography>
            <Typography variant="body2">
                {computeStock(stock)}
            </Typography>
            <Typography style={{fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)"}}
                        sx={{mb: 1.5}}
                        color="text.secondary"
            >
                {price} Lei
            </Typography>
            <AddToCart id={id} name={name} price={price} imageSrc={imageSrc}/>
        </CardContent>

    </React.Fragment>
};



const ProductsGrid = () => {


    const matches = useMediaQuery('(min-width:820px)');
    const matches_1080 = useMediaQuery('(min-width:1080px)');

    const [pos, setPos] = useState("relative");
    const [marg, setMarg] = useState("0px");

    useEffect(() => {
        // Update the document title using the browser API
        window.addEventListener('scroll', listenScrollEvent)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listenScrollEvent = () => {
        console.log(window.scrollY);
        if (window.scrollY > 330) {
            setPos("fixed");
            setMarg("-330px");
        } else {
            setPos("relative");
            setMarg("0px");
        }
    }

    const CreateProducts = () => {
        const rows = [];

        if (productsStore.products.length > 1) {
            let aux = productsStore.products[0].categories[0].name;
            let gridAux = [];
            let i = 0;
            while (i < productsStore.products.length)  {
                let currentLabel = productsStore.products[i].categories[0].name;
                while (aux === currentLabel && i < productsStore.products.length) {
                    gridAux.push(CreateCard(
                        productsStore.products[i].name,
                        productsStore.products[i].images[0].src,
                        productsStore.products[i].price,
                        productsStore.products[i].id,
                        productsStore.products[i].stock_status
                    ))
                    i++;
                    if (i < productsStore.products.length) {
                        currentLabel = productsStore.products[i].categories[0].name;
                    }
                }
                rows.push
                (<Section id={aux}>
                    <Divider textAlign="center" className="grid__title" id={aux + " Category"}><Chip className="grid__chip" label={aux}/></Divider>
                    <Grid container spacing={1}>{gridAux}</Grid>
                </Section>)

                gridAux = [];
                aux = currentLabel;
            }
        }
        return rows;
    }

    const CreateCard = (name, src, price, id, stock) => {
        return  <Grid item xs={matches ? matches_1080 ? 3 : 6 : 6}>
            <Link to={"/produs/"+id} style={{ textDecoration: "none"}}>
                <Card className="product" variant="outlined">{card(id, name, src, price, stock)}</Card>
            </Link>
        </Grid>
    }

    return (
        <Container style={{ position: "relative", zIndex: 10, marginTop: "-179px"}} maxWidth="lg">

            <Grid container spacing={5} >

                    <Grid item xs={matches_1080 ? 3 : 4} style={{ display: matches ? "block" : "none"}}>
                        <Card variant="outlined" style={{ width: "260px", position: pos,
                            marginTop: marg, zIndex: 1 }}>{nav}</Card>
                    </Grid>

                    <Grid item xs={matches ? matches_1080 ? 9: 8 : 12} style={{ marginTop: "80px"}}>
                        { CreateProducts() }
                    </Grid>

            </Grid>

        </Container>
    );
};

export default view(ProductsGrid);