import React, {useEffect} from 'react';
import InfoBar from "../components/InfoBar/InfoBar";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../components/Footer/Footer";
import BottomBar from "../components/BottomBar/BottomBar";
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    Link,
    Rating,
    Typography
} from "@mui/material";


import ProductGalery from "../components/ProductGalery/ProductGalery";
import AddToCart from "../components/AddToCart/AddToCart";
import DescriereRecenzii from "../components/DescriereRecenzii/DescriereRecenzii";
import {FacebookShareButton, WhatsappShareButton} from "react-share";

import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import useMediaQuery from "@mui/material/useMediaQuery";
import {getProduct} from "../requests/Products";
import {view} from '@risingstack/react-easy-state';
import {productsStore} from "../state/State";
import {computeStock} from "../util/Stock";


const Produs = () => {

    let productName = "";
    let categoryName = "";
    let sku = "";
    let price = "";
    let images = [];
    let descriere = "";
    let producator = "";
    let stoc = "";
    let id = "";
    let thumbnail = "test.jpg";

    useEffect(() => {
        window.scrollTo(0, 0);
        const id = window.location.pathname.split("/").pop();
        console.log(id);
        getProduct(id);
        // productName = productsStore.product.categories[0].name;
    }, [])

    const matches = useMediaQuery('(min-width:820px)');

    const buildImagesArray = (images) => {
        let auxImages = [];
        debugger;
        for (let i = 0; i < images.length; i++) {
            let ext = images[i].src.split('.').pop();
            auxImages.push({
                original: images[i].src,
                thumbnail: images[i].src.replace(/\.[^/.]+$/, "") + "-100x100." + ext
            })
        }
        return auxImages;
    }

    (() => {
        if ("message" in productsStore.product) {
            if (productsStore.product.message === "Invalid ID.") {
                window.location.replace(window.location.pathname + "/404");
            }
        }
        console.log(productsStore.product);
        if (Object.keys(productsStore.product).length !== 0) {
            productName = productsStore.product.name;
            categoryName = productsStore.product.categories[0].name;
            price = productsStore.product.price;
            sku = productsStore.product.sku;
            images = buildImagesArray(productsStore.product.images);
            descriere = productsStore.product.description;
            producator = productsStore.product.attributes[0].options[0];
            stoc = productsStore.product.stock_status;
            id = productsStore.product.id;
            thumbnail = productsStore.product.images[0].src;
        }
    })()



    return (
        <div>

            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0" cart={true}/>


            <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "50px"}}>
                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "0.9em", marginBottom: "7px" }}>
                    <Link underline="hover" color="inherit" href="/">
                        Acasa
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                    >
                        { categoryName }
                    </Link>
                </Breadcrumbs >
                <Typography variant="h5"> {productName}</Typography>
                <Grid container spacing={5} >
                    <Grid item xs={6} style={{ fontSize: "0.9em", marginTop: "11px", color: "#00000099" }}>
                        Cod produs: {sku}
                    </Grid>
                    <Grid item xs={matches ? 6 : 12} style={{ textAlign: matches ? "end" : "start", marginTop: matches ? "11px" : "-11px"}}>
                        <WhatsappShareButton
                            url={"Google.ro"}
                            quote={"Facebook"}
                            className="Demo__some-network__share-button"
                        >
                            <Button variant="outlined" size="small"  startIcon={<WhatsAppIcon />} className="whatsapp__button">
                                Share
                            </Button>
                        </WhatsappShareButton>

                        <FacebookShareButton
                            url={"Google.ro"}
                            quote={"WhatsApp"}
                            className="Demo__some-network__share-button"
                        >
                        <Button variant="outlined" size="small"  startIcon={<FacebookIcon />} className="facebook__button">
                            Share
                        </Button>
                        </FacebookShareButton>

                    </Grid>
                </Grid>
                <br/>
                <Divider />
                <br/>
                <Grid container spacing={5}>
                    <Grid item xs={matches ? 6 : 12} >
                        <ProductGalery images={images}/>
                    </Grid>
                    <Grid item xs={matches ? 6 : 12} >
                        <Card sx={{ minWidth: 275 }} elevation={0}>
                            <CardContent>
                                {/*Price*/}
                                <Typography variant="h4" component="div" style={{ fontWeight: "bold", display: "inline-block"}}>
                                    {price.split(".")[0]}
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontWeight: "bold", display: "inline-block"}}>
                                    .{price.split(".")[1]}
                                </Typography>
                                <div style={{ display: "inline-block"}}>
                                    <Typography variant="h6" component="div" className="price-tva" >
                                        TVA inclus
                                    </Typography>
                                    <Typography variant="h6" component="div" className="price-pack" >
                                        lei
                                    </Typography>
                                </div>

                                <div className="buy__container">
                                    <Typography >
                                        Producător: {producator}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }}  color="text.secondary">
                                        { computeStock(stoc) }
                                    </Typography>

                                    <Typography>Nu exista recenzii</Typography>
                                    <Rating name="read-only" style={{ marginLeft: "-5px"}} value={5} readOnly />
                                    <br/>
                                    <br/>
                                    <Grid container spacing={5}>
                                        <Grid item xs={6} >
                                            <AddToCart id={id} name={productName} price={price} imageSrc={thumbnail}/>
                                        </Grid>

                                    </Grid>
                                </div>
                                <br/>
                                <br/>
                                <DescriereRecenzii descriere={descriere}/>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
                <br/>



            </Container>
            {/*<MoreProducts/>*/}
            <Footer />
            <BottomBar mobileProduct={true}/>
        </div>
    );
};

export default view(Produs);