import {store} from '@risingstack/react-easy-state';

let productsStore = store({
    products: {},
    product: {},
    menuProducts: [
        "Tigari Electronice",
        "Tutun",
        "Tuburi Tigari Standard",
        "Tuburi Tigari Slim",
        "Tuburi Tigari Click",
        "Tigari De Foi",
        "Solutie Umidificat Tutun",
        "Aparate Injectat Tutun",
        "Masini Pentru Taiat Tutun",
        "Tabachere"
    ],
    lastRes: {}
});

export { productsStore }