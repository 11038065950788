import React, {useEffect, useState} from 'react';

import "./Navigation.css";
import {Badge, Button, Container, Menu} from "@mui/material";

import logo_normal from "../../logo1.png";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NavigationProducts from "../NavigationProducts/NavigationProducts";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link} from "react-router-dom";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import {useCart} from "react-use-cart";

const Navigation = ({ bgType, topType, cart }) => {

    const [bg, setBg] = useState(bgType);
    const [logo, setLogo] = useState(logo_normal)
    const [top, setTop] = useState(topType);
    const [position, setPosition] = useState("relative");

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseCart = () => {
        setOpen(false);
    };

    const matches = useMediaQuery('(min-width:820px)');

    useEffect(() => {
        // Update the document title using the browser API
        window.addEventListener('scroll', listenScrollEvent)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listenScrollEvent = () => {
        // Bar background
        if (window.scrollY > 16) {
            setBg("#3B1900");
            setPosition("fixed");
            setTop("0");
            setLogo(logo_normal);
        } else {
            setBg(bgType);
            setPosition("relative");
            setTop(topType);
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClose() {
        setAnchorEl(null);
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const { totalItems } = useCart();

    return (
        <div className="navigation" style={{ backgroundColor: bg, position: position, top: top }}>
            <Container className="navigation__container">

                <Link to="/">
                    {cart ?
                        <img src={logo} onClick={scrollToTop} alt="Logo" className="logo"
                             style={{left: matches ? "50%" : "30%"}}/>
                        :
                        <img src={logo} onClick={scrollToTop} alt="Logo" className="logo"
                             style={{left: "50%" }}/>
                    }
                </Link>

                {cart ?
                    <Badge badgeContent={totalItems} color="primary" className="cart" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>

                        <Button variant="text" style={{color: "white", textTransform: "none"}}
                                onClick={handleClickOpen}
                                startIcon={<ShoppingCartIcon/>}>
                            Cosul meu
                        </Button>

                    </Badge>
                    : ""
                }

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ width: "650px"}}
                    MenuListProps={{ onMouseLeave: handleClose }}
                >
                    <NavigationProducts/>
                </Menu>
            </Container>

                <ShoppingCart open={open} handleClose={handleCloseCart}/>

        </div>
    );
};

export default Navigation;