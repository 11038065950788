import React, {useEffect} from 'react';
import InfoBar from "../components/InfoBar/InfoBar";
import Navigation from "../components/Navigation/Navigation";
import {Container, Typography} from "@mui/material";
import Footer from "../components/Footer/Footer";
import BottomBar from "../components/BottomBar/BottomBar";

const TermeniSiConditii = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div>
            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0"/>

            <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "50px"}}>
                <br/>
                <br/>
                <Typography variant="h5" component="div" style={{textAlign: "center"}}>
                    Termeni si conditii
                </Typography>
                <br/>
                <br/>


                <p>
                    Legislația în vigoare interzice minorilor achiziționarea produselor din
                    tutun, de aceea, în momentul achiziționării acestor produse, orice
                    utilizator declară pe propria răspundere următoarele:
                </p>
                <p>
                    <strong
                    >“Declar pe propria raspundere că am vârsta de cel puțin 18 ani impliniți
                        și că prin plasarea comenzii nu încalc prevederile legale în
                        vigoare”</strong
                    >
                </p>
                <p>
                    Vizitarea, folosirea, sau comandarea produselor vizualizate pe
                    <strong>tabacshop.ro</strong>&nbsp;implică&nbsp;acceptarea Condiţiilor de
                    utilizare ce vor fi detaliate mai jos. Pentru folosirea în bune condiţii a
                    site-ului, se recomandă&nbsp;citirea cu atenţie a Termenilor şi Condiţiilor.
                </p>
                <p>
                    www.tabacshop.ro&nbsp;este administrat de Blk Trade Market srl
                    cu&nbsp;sediul in Iasi, înregistrată&nbsp;la Registrul Comertului cu nr
                    J22/855/2019,&nbsp;CUI 40638971, capital social 200 lei.
                </p>
                <p>
                    Ne rezervăm dreptul de a efectua modificari ale acestor Termeni şi Condiţii
                    precum şi orice modificari site-ului tabacshop.ro&nbsp;fară&nbsp;o
                    notificare prealabilă. Accesând pagina Termeni şi Condiţii, puteţi citi
                    intotdeauna cea mai recentă&nbsp;versiune a acestor prevederi.
                </p>
                <p><strong>Obligaţiile de înregistrare:</strong></p>
                <p>
                    Pentru a putea cumpăra de pe www.tabacshop.ro, trebuie să&nbsp;introduceţi
                    datele dumneavoastră&nbsp;pe website-ul nostru. În utilizarea serviciului,
                    declaraţi că&nbsp;veţi furniza informaţii reale, corecte, actuale şi
                    complete despre dumneavoastră. În situaţia în care considerăm
                    că&nbsp;această&nbsp;obligaţie a fost încălcată, ne rezervăm dreptul de a
                    vă&nbsp;bloca accesul la utilizarea serviciului, pe
                    perioadă&nbsp;determinată&nbsp;sau nedeterminată, fără&nbsp;nicio notificare
                    prealabilă.
                </p>
                <p><strong>Preţ şi modalităţi de plată:</strong></p>
                <p>
                    &nbsp;Pretul de achiziţie prezentat pe factură&nbsp;va fi acelaşi cu cel
                    prezentat pe site la momentul achiziţiei, preţ&nbsp;la care se adaugă şi
                    costurile de livrare. Plata produselor se efectuează în numerar/cash, la
                    livrarea produsului, prin credit/debit card sau ordin de plata/transfer
                    bancar la plasarea comenzii online.
                </p>
                <p>
                    <strong><u>Informaţii privitoare la livrare:</u></strong>
                </p>
                <p>
                    Livrarea se face prin firma DPD curier la domiciliul clientului,&nbsp;în
                    termen de 24 -48 ore&nbsp;de la expedierea comenzii. În ziua în care coletul
                    cu comanda dumneavoastră va fi expediat, veţi fi înştiinţat telefonic de
                    către noi sau prin sms catre numarul dvs de contact. La sosire coletul poate
                    fi preluat de orice membru al familiei. Taxa minimă pentru un colet cu plata
                    ramburs este de 18 ron.
                </p>
                <p>
                    Produsele se livrează în limita stocului disponibil. În cazul în care unul
                    sau mai multe dintre produsele comandate nu se mai află pe stoc veţi fi
                    inștiinţat în acest sens. În unele cazuri, produsul comandat poate fi
                    înlocuit cu unul asemănător dacă dumneavoastră doriti şi sunteţi de acord cu
                    acest lucru.
                </p>
                <p>
                    Răspunderea pentru orice deteriorare cauzată produsului, coletului sau
                    pachetului trimis de către noi, revine societăţii de curierat conform
                    legislaţiei in vigoare. Produsele vor fi înlocuite de către noi dacă se va
                    face dovada culpei curierului şi vom fi inştiinţaţi în termen de 24 ore de
                    la primirea coletului.
                </p>
                <p>
                    Comenzile preluate până la ora 13:00, exceptând sâmbăta, duminica şi
                    sărbătorile legale, vor fi expediate în aceeaşi zi&nbsp;cu serviciul de
                    curierat şi ajung la destinaţie în&nbsp; 24-48 ore.&nbsp;<strong
                >În&nbsp;localităţile din mediul rural</strong
                >, timpul de expediere este de 2-3 zile lucratoare. În cazul în care doriţi
                    să specificaţi anumite aspecte referitoare la livrare cum ar fi ora de
                    livrare, adresa sau puncte de reper, vă rugăm să introduceţi în căsuţa de
                    observaţii aceste lucruri înainte de finalizarea comenzii.
                </p>
                <p>
                    Dacă aveţi de facut modificari la comandă sau adresa dvs. nu este cea
                    corectă, ne puteţi contacta la telefon 0763720000.
                </p>
                <p><strong>Drepturile de autor (Copyright)</strong></p>
                <p>
                    Întregul conţinut al site-ului www.tabacshop.ro, incluzând, texte, imagini,
                    grafice, elemente de grafică web, scripturi si orice alte date, este
                    proprietatea Blk Trade market srl sau a furnizorilor săi şi este protejat
                    conform Legii drepturilor de autor şi legilor privind dreptul de proprietate
                    intelectuală şi industrială. Folosirea fără acordul scris a oricaror
                    elemente de pe site-ul www.tabacshop.ro, sau a oricăror elemente enumerate
                    mai sus se pedepseşte conform legilor în vigoare. Pentru a reclama
                    drepturile de proprietate intelectuală puteţi folosi adresa de mail
                    blktrademarket@gmail.com
                </p>
                <p>&nbsp;</p>
                <p><strong>Politica de confidenţialitate</strong></p>
                <p>
                    Pastrarea confidentialitatii informatiilor despre utilizatorii nostri este
                    foarte importanta pentru noi. Tabacshop.ro nu va vinde, inchiria sau oferi
                    sub orice forma adresa ta de e-mail sau orice alta informatie cu caracter
                    personal. Pentru mai multe detalii, ne puteti contacta oricand. Tabacshop.ro
                    va pastra confidentialitatea informatiilor de orice natura pe care le
                    furnizati.
                </p>
                <p><strong>Prelucrarea datelor cu caracter personal</strong></p>
                <ul>
                    <li>
                        Conform cerintelor Legii nr. 677/2001 pentru protectia persoanelor cu
                        privire la prelucrarea datelor cu caracter personal si libera circulatie a
                        acestor date, modificata si completata, tabacshop.ro are obligatia de a
                        administra in conditii de siguranta si numai pentru scopurile specificate,
                        datele personale pe care ni le furnizezi.
                    </li>
                </ul>
                <ul>
                    <li>Scopul colectarii datelor este:</li>
                </ul>
                <p>
                    -informarea Clientilor/Cumparatorilor privind validarea, expedierea si
                    facturarea Comenzilor, rezolvarea anularilor sau a problemelor de orice
                    natura referitoare la o Comanda, la Bunurile si/sau serviciile achizitionate
                </p>
                <p>
                    -trimiterea de Newslettere si/sau alerte periodice, prin folosirea postei
                    electronice (e-mail, SMS)
                </p>
                <p>
                    -de cercetare de piata, de urmarire si monitorizare a vanzarilor si
                    comportamentul Clientului/Cumparatorului.
                </p>
                <ul>
                    <li>
                        Prin completarea datelor in formularul de comanda Cumparatorul declara si
                        accepta neconditionat ca datele sale personale sa fie incluse in baza de
                        date a tabacshop.ro si isi da acordul expres si neechivoc ca toate aceste
                        date personale sa fie stocate, utilizate si prelucrate in scopul prevazut
                        mai sus.
                    </li>
                </ul>
                <ul>
                    <li>
                        Prin citirea Documentului ati luat la cunostinta faptul ca va sunt
                        garantate drepturile prevazute de lege, respectiv dreptul la informare,
                        dreptul de acces la date, dreptul de interventie, dreptul de opozitie,
                        dreptul de a nu fi supus unei decizii individuale, dreptul de va adresa
                        justitiei in caz de incalcare a drepturilor&nbsp;&nbsp; garantate de Legea
                        677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu
                        caracter personal si libera circulatie a acestor date.
                    </li>
                </ul>
                <ul>
                    <li>
                        De asemenea, tabacshop.ro poate furniza datele cu caracter personal ale
                        Cumparatorului altor companii cu care se afla in relatii de parteneriat,
                        dar numai in temeiul unui angajament de confidentialitate din partea
                        acestora si numai in scopurile mentionate prin care garanteaza ca aceste
                        date sunt pastrate in siguranta si ca furnizarea acestor informatii
                        personale se face conform legislatiei in vigoare, dupa cum urmeaza:
                        furnizorilor de servicii de curierat, furnizorilor de servicii de
                        marketing, furnizorilor de servicii de plata/bancare, telemarketing sau
                        alte servicii, furnizate de companii cu care putem dezvolta programe
                        comune de ofertare pe piata a&nbsp; Bunurilor si Serviciilor noastre,
                        asigurători.
                    </li>
                </ul>
                <ul>
                    <li>
                        Informatiile Cumparatorului cu caracter personal pot fi furnizate si catre
                        Parchetul General, Politie, instantele judecatoresti si altor organe
                        abilitate ale statului, in baza si in limitele prevederilor legale si ca
                        urmare a unor cereri expres formulate.
                    </li>
                </ul>
                <p><strong>Responsabilităţi privind produsele</strong></p>
                <p>
                    &nbsp;Blk Trade market srl nu îşi asumă responsabilitatea şi nu poate fi
                    făcută responsabilă pentru orice pagube apărute prin folosirea produselor
                    achiziţionate prin intermediul acestui site, în alte scopuri decât cele
                    menţionate de producător. Returnarea produselor se poate efectua în
                    conformitate cu dispozitiile OG 130/2000, iar pentru mai multe detalii va
                    rugăm sa vizitaţi sectiunea Returnare produse.
                </p>
                <p>.</p>
                <p><strong>Litigii</strong></p>
                <p>
                    Prin folosirea, vizualizarea sau cumpărarea produselor de pe acest site,
                    utilizatorul a luat la cunoştinţă asupra faptului ca legile române vor
                    guverna Termenii şi Condiţiile de mai sus şi orice dispută de orice fel care
                    ar putea să apară între utilizator şi Blk Trade market srl. În cazul unor
                    eventuale conflicte intre Blk Trade market srl şi clienţii săi, se va
                    incerca mai întâi rezolvarea acestora pe cale amiabilă în minim 30 de zile
                    lucrătoare. Dacă rezolvarea pe cale amiabilă nu va fi posibilă, conflictul
                    va fi soluţionat în instanţa competentă în conformitate cu legile române în
                    vigoare.
                </p>
                <p><strong>Diverse</strong></p>
                <p>
                    Dacă oricare dintre clauzele de mai sus va fi găsită nulă sau nevalidă,
                    indiferent de cauză, aceasta nu va afecta valabilitatea celorlalte clauze.
                    Odată cu lansarea comenzii, clientul acceptă fără obiecţiuni Condiţiile şi
                    termenii de utilizare, valoarea acestora fiind aceeaşi cu un contract
                    valabil incheiat.
                </p>
                <p>
                    <strong><u>Plăți</u></strong>
                </p>
                <p><strong>Plata în numerar</strong></p>
                <p>
                    Plata cu numerar se face în ron, integral, la livrare. Valoarea produselor
                    este cea din momentul confirmării comenzii. În cazul livrării produselor de
                    către curieri, plata se va face către comisionarul care
                    efectuează&nbsp;livrarea.
                </p>
                <p><strong>Plata online folosind cardul</strong></p>
                <p>
                    Se poate efectua plata online cu cardul personal sau al firmei
                    dumneavoastră, în condiţii de siguranţă&nbsp;deplină. Cardurile acceptate la
                    plată&nbsp;sunt cele emise sub siglele VISA si MASTERCARD (inclusiv Maestro,
                    dacă&nbsp;au cod CVV2/CVC2).
                </p>
                <p>Nu este perceput nici un comision suplimentar pentru tranzacţii.</p>
                <p>
                    Pentru finalizarea corectă&nbsp;a tranzacţiei, trebuie sa furnizaţi codul
                    cardului (toate cifrele din cele patru grupe de pe faţa cardului,
                    fără&nbsp;spaţii), dată&nbsp;de expirare şi ultimele trei cifre de pe
                    spatele cardului inscripţionate pe banda cu semnătura dumneavoastră&nbsp;(în
                    funcţie de card, pot lipsi).&nbsp;Indiferent de valuta pe care o aveţi în
                    cont, tranzacţiile se fac în lei, la cursul de schimb al băncii
                    dumneavoastră. Tabacshop.ro&nbsp;nu solicită&nbsp;şi nu stochează&nbsp;nici
                    un fel de detalii referitoare la cardul dumneavoastră.
                </p>
                <p><strong>Transfer bancar / Ordin de plată</strong></p>
                <p>
                    Plata prin Ordin de plată&nbsp;se face doar în baza facturii proforme emise
                    de tabacshop.ro, în urma confirmării telefonice a comenzii.&nbsp;Livrarea
                    mărfii se face după&nbsp;confirmarea plăţii, în contul de mai jos, a
                    proformei emise.
                </p>
                <p>
                    În cazul persoanelor juridice pentru a intra in posesia coletului trebuie
                    să&nbsp;prezinte ştampila firmei sau o împuternicire semnată.
                </p>
                <p>
                    Nume firma:&nbsp;SC BLK Trade Market srl<br />Cont
                    lei:&nbsp;RO64INGB0000999908844025 deschis la ING Bank, Iasi<br />Cod unic
                    inregistrare: 40638971
                </p>
                <p>
                    <strong
                    ><em
                    >Fiind de acord cu aceşti Termeni şi Condiţii clientul îşi asumă în
                        totalitate drepturile şi obligaţiile ce decurg din cumpărarea din
                        magazinul virtual tabacshop.ro.</em
                    ></strong
                    >
                </p>


            </Container>

            <Footer />
            <BottomBar />
        </div>
    );
};

export default TermeniSiConditii;