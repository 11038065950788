import React, {useEffect} from 'react';
import InfoBar from "../components/InfoBar/InfoBar";
import Navigation from "../components/Navigation/Navigation";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItem,
    MenuItem,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";

import Select from '@mui/material/Select';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Footer from "../components/Footer/Footer";
import FabButton from "../components/Fab/Fab";
import {useCart} from "react-use-cart";
import {useForm} from "react-hook-form";
import {postOrder} from "../requests/Products";

const JUDETE = ["Bucuresti", "Alba", "Arad", "Arges", "Bacau", "Bihor", "Bistrita - Nasaud", "Botosani", "Braila", "Brasov", "Buzau", "Calarasi", "Caras - Severin", "Cluj", "Constanta", "Covasna", "Dambovita", "Dolj", "Galati", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomita", "Iasi", "Ilfov", "Maramures", "Mehedinti", "Mures", "Neamt", "Olt", "Prahova", "Salaj", "Satu Mare", "Sibiu", "Suceava", "Teleorman", "Timis", "Tulcea", "Valcea", "Vaslui", "Vrancea"];

const Checkout = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [district, setDistrict] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const { cartTotal, items } = useCart();
    const { register, handleSubmit } = useForm();

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleChange = (event) => {
        setDistrict(event.target.value);
    };

    const onSubmit = (data) => {
        console.log(data);

        let myArray = data.name.split(" ");
        let first_name = myArray[0];
        let last_name = myArray[1];
        let request = {};
        let billing = {};
        let shipping = {};
        let line_items = [];
        let shipping_lines = [{ method_id: "DPD",  method_title: "Curier", total: "20.00"}]

        request["payment_method"] = "cod";
        request["payment_method_title"] = "Cash on delivery";
        request["set_paid"] = true;
        billing["first_name"] = first_name;
        billing["last_name"] = last_name;
        billing["address_1"] = data.address_1;
        billing["address_2"] = "";
        billing["city"]  = data.city;
        billing["state"] = data.state;
        billing["postcode"] = data.postcode;
        billing["country"] = "RO";
        billing["email"] = data.email;
        billing["phone"] = data.phone;
        request["billing"] = billing;

        shipping["first_name"] = first_name;
        shipping["last_name"] = last_name;
        shipping["address_1"] = data.address_1;
        shipping["address_2"] = "";
        shipping["city"]  = data.city;
        shipping["state"] = data.state;
        shipping["postcode"] = data.postcode;
        shipping["country"] = "RO";
        request["shipping"] = shipping;

        items.forEach(element => line_items.push({ product_id: element.id, quantity: element.quantity}));
        request["line_items"] = line_items;
        request["shipping_lines"] = shipping_lines;

        console.log(data);
        console.log(request);

        postOrder(request, data);

    }
    const onError = (errors) => {
        setOpen(true);
        setMessage(Object.values(errors)[0].message);
    }

    const vertical = "bottom";
    const horizontal = "center";

    return (
        <div style={{ backgroundColor: "#f8f8f8"}}>
            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0" cart={false}/>
            <Typography variant="h5" component="div" style={{textAlign: "center", marginTop: "40px"}}>
                Simplu, rapid, fara cont
            </Typography>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleClose}  severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "50px"}}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={5} columns={{ xs: 4, sm: 8, md: 12 }} >
                    <Grid item xs={4} sm={4} md={4}>
                        <Card sx={{ minWidth: 275 }} variant="outlined">
                            <CardContent>
                                <Typography color="black" gutterBottom style={{ fontWeight: "bold"}}>
                                    Adresa de livrare
                                </Typography>
                                <br/>
                                <TextField id="outlined-basic" {...register("name", {
                                    required: {value: true, message: "Numele nu este complectat" },
                                    pattern: {value: /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/i , message: "Trebuie pus spatiu intre nume si prenume"}
                                    })} label="Nume / Prenume" variant="outlined" style={{ width: "100%"}}/>
                                <TextField id="outlined-basic" {...register("email", {
                                    required: {value: true, message: "Email-ul nu este complectat" },
                                    pattern: {value: /^\S+@\S+$/i , message: "Email-ul nu este valid"}
                                })}
                                           label="Email" variant="outlined" style={{ width: "100%", marginTop: "10px"}}/>
                                <TextField id="outlined-basic" {...register("phone", {
                                    required: {value: true, message: "Numarul de telefon nu este complectat" },
                                    pattern: {value: /^(\+\d{1,2}\s?)?1??\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i , message: "Numarul de telefon nu este valid"}
                                })}
                                           label="Telefon" type="number" variant="outlined" style={{ width: "100%", marginTop: "10px"}}/>
                                <FormControl fullWidth style={{ marginTop: "10px"}} >
                                    <InputLabel id="demo-simple-select-label">Judet</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={district}
                                        {...register("city", {
                                            required: {value: true, message: "Judetul nu este complectat" },
                                        })}
                                        onChange={handleChange}
                                        label={"Judet"}
                                    >
                                        {JUDETE.map((judet) => (
                                            <MenuItem value={judet}>{judet}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField id="outlined-basic" label="Localitatea" {...register("state", {
                                    required: {value: true, message: "Localitatea nu este complectata" },
                                })}
                                           variant="outlined" style={{ width: "100%", marginTop: "10px"}}/>
                                <TextField id="outlined-basic" {...register("postcode", {
                                    required: {value: true, message: "Codul postal nu este complectat" },
                                })} label="Cod Postal" type="number" variant="outlined" style={{ width: "100%", marginTop: "10px"}}/>

                                <TextField id="outlined-basic" {...register("address_1", {
                                    required: {value: true, message: "Adresa nu este complectata" },
                                })} label="Strada, Numar, Bloc, Ap., alte indicatii" variant="outlined" style={{ width: "100%", marginTop: "10px"}}/>

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} >
                        <Card sx={{ minWidth: 275 }} variant="outlined">
                            <CardContent>
                                <Typography color="black" gutterBottom style={{ fontWeight: "bold"}}>
                                    Metoda de plata
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked checked={true}/>}
                                    label="Plata la livrare (Ramburs)"
                                    style={{ marginTop: "20px"}}
                                />
                                <Typography color="black" gutterBottom style={{ fontWeight: "bold", marginTop: "20px"}}>
                                    Modalitate de livrare
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked checked={true}/>}
                                    label="Transport curier oriunde in tara - 20 Ron"
                                    style={{ marginTop: "20px"}}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4} sm={8} md={4}>
                        <Card sx={{ minWidth: 275 }} variant="outlined">
                            <CardContent>
                                <Typography color="black" gutterBottom style={{ fontWeight: "bold"}}>
                                    Sumar Cos
                                </Typography>
                                <List dense={true}>
                                        <ListItem secondaryAction={cartTotal + " Ron"}>
                                            <ListItemText primary="Produse:"/>
                                        </ListItem>
                                        <ListItem secondaryAction={"20 Ron"}>
                                            <ListItemText primary="Transport:"/>
                                        </ListItem>
                                        <Divider variant="middle" style={{ marginTop: "10px", marginBottom: "10px"}} />
                                        <ListItem secondaryAction={(cartTotal + 20) + " Ron"} style={{ fontWeight: "bold" }}>
                                            <ListItemText primary={<span style={{ fontWeight: "bold" }}>Total:</span>} />
                                        </ListItem>
                                </List>

                            </CardContent>
                            <CardActions>
                                <Button type="submit" style={{ width: "100%", textTransform: "none", backgroundColor: "#3D1A00", color: "white", margin: "10px", marginTop: "-10px"}} autoFocus>
                                    TRIMITE COMANDA
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                </form>
            </Container>
            <Footer/>
            <FabButton/>
        </div>
    );
};

export default Checkout;