import React from 'react';

import "./DecriereRencenzii.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {Typography} from "@mui/material";

import WpComments from "react-wordpress-comments"
import "react-wordpress-comments/css/styles.css"

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const DescriereRecenzii = ({ descriere }) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%'}} >
            <Box style={{ textTransform: "none !important"}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Descriere" />

                    <Tab label="Recenzii"  />
                </Tabs>
            </Box>
            <TabPanel style={{ marginTop: "-17px", marginBottom: "-40px"}} value={value} index={0} >
                <div className="panel__space">
                    <div  dangerouslySetInnerHTML={{__html: descriere}}></div>
                </div>
            </TabPanel>
            <TabPanel  value={value} index={1}>
                <div className="panel__space">
                    <WpComments
                        maxDepth={3}
                        pageId={619}
                        hostUrl="https://tabacshop.ro/"
                        allowComments={true}
                    />
                </div>
            </TabPanel>
        </Box>
    );
};

export default DescriereRecenzii;