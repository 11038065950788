import React, {Fragment} from 'react';

import "./NavigationProducts.css";
import {Button} from "@mui/material";


import {useScrollSection} from 'react-scroll-section';

const NavigationProducts = () => {

    const h1 = useScrollSection('Tigari Electronice');
    const h2 = useScrollSection('Tutun');
    const h3 = useScrollSection('Tuburi Tigari Standard');
    const h4 = useScrollSection('Tuburi Tigari Slim');
    const h5 = useScrollSection('Tuburi Tigari Click');
    const h6 = useScrollSection('Tigari De Foi');
    const h7 = useScrollSection('Solutie Umidificat Tutun');
    const h8 = useScrollSection('Aparate Injectat Tutun');
    const h9 = useScrollSection('Masini Pentru Taiat Tutun');
    const h10 = useScrollSection('Tabachere');


    return (
        <Fragment>

            <Button variant="text"
                    onClick={h1.onClick}
                    style={{ backgroundColor: h1.selected ? "#3D1A00" : "#fff",
                        color: h1.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tigari Electronice
            </Button>
            <Button variant="text"
                    onClick={h2.onClick}
                    style={{ backgroundColor: h2.selected ? "#3D1A00" : "#fff",
                        color: h2.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tutun
            </Button>
            <Button variant="text"
                    onClick={h3.onClick}
                    style={{ backgroundColor: h3.selected ? "#3D1A00" : "#fff",
                        color: h3.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tuburi Tigari Standard
            </Button>
            <Button variant="text"
                    onClick={h4.onClick}
                    style={{ backgroundColor: h4.selected ? "#3D1A00" : "#fff",
                        color: h4.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tuburi Tigari Slim
            </Button>
            <Button variant="text"
                    onClick={h5.onClick}
                    style={{ backgroundColor: h5.selected ? "#3D1A00" : "#fff",
                        color: h5.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tuburi Tigari Click
            </Button>
            <Button variant="text"
                    onClick={h6.onClick}
                    style={{ backgroundColor: h6.selected ? "#3D1A00" : "#fff",
                        color: h6.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tigari De Foi
            </Button>
            <Button variant="text"
                    onClick={h7.onClick}
                    style={{ backgroundColor: h7.selected ? "#3D1A00" : "#fff",
                        color: h7.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Solutie Umidificat Tutun
            </Button>
            <Button variant="text"
                    onClick={h8.onClick}
                    style={{ backgroundColor: h8.selected ? "#3D1A00" : "#fff",
                        color: h8.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Aparate Injectat Tutun
            </Button>
            <Button variant="text"
                    onClick={h9.onClick}
                    style={{ backgroundColor: h9.selected ? "#3D1A00" : "#fff",
                        color: h9.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Masini Pentru Taiat Tutun
            </Button>
            <Button variant="text"
                    onClick={h10.onClick}
                    style={{ backgroundColor: h10.selected ? "#3D1A00" : "#fff",
                        color: h10.selected ? "white" : "#000"}}
                    disableElevation
                    className="productMenu__button">
                Tabachere
            </Button>

        </Fragment>
    );
};

export default NavigationProducts;