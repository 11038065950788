import React, {useEffect} from 'react';
import InfoBar from "../components/InfoBar/InfoBar";
import Navigation from "../components/Navigation/Navigation";
import {Container, Typography} from "@mui/material";
import Footer from "../components/Footer/Footer";
import BottomBar from "../components/BottomBar/BottomBar";


const ReturnareProduse = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <InfoBar/>
            <Navigation bgType="#3D1A00" topType="0"/>

            <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "50px"}}>
                <br/>
                <Typography variant="h5" component="div" style={{textAlign: "center"}}>
                    Returnare produse
                </Typography>
                <br/>
                <br/>

                <Typography>Detalii despre dreptul la retur:</Typography>
                <Typography>
                    Cumpărătorul are dreptul să returneze un bun în termen de 14 zile
                    calendaristice, fără invocarea niciunui motiv și fără a suporta alte costuri
                    decât cele de livrare. Astfel, potrivit OUG nr 34/2014, perioada de
                    returnare a unui bun expiră în termen de 14 zile din ziua în care
                    cumparatorul intră în posesia fizică a bunului.
                </Typography>
                <Typography>
                    În cazul în care comanda este achitată, vânzătorul va rambursa suma în maxim
                    14 (paisprezece) zile de la data informarii vânzătorului de către cumparator
                    asupra deciziei sale de returnare a produsului. Suma va fi restituită în
                    contul bancar indicat de către cumpărător. Vânzătorul va putea amâna
                    rambursarea sumei până la primirea bunurilor vândute sau până la primirea
                    unei dovezi conform căreia acestea au fost expediate.
                </Typography>
                <Typography>
                    Condiții generale pentru returul produselor vandute și expediate de
                    www.tabacshop.ro:
                </Typography>
                <ul>
                    <li>
                        Produsele pe care le returnezi trebuie să fie în aceeași stare în care
                        le-ai primit.
                    </li>
                    <li>
                        Returul se face cu etichetele inițiale intacte, certificatul de garanție
                        în original (dacă a fost emis de producător/distribuitor) și toate
                        documentele cu care a fost livrat produsul.
                    </li>
                    <li>
                        Produsul trebuie să fie în aceeași stare în care a fost livrat și însotit
                        de accesoriile cu care a fost livrat.
                    </li>
                </ul>
                <Typography>
                    Dacă ai cumpărat mai multe produse de același fel și vrei să le returnezi pe
                    toate, asigură-te că doar unul a fost desigilat. Returul celorlalte produse
                    se acceptă doar dacă sunt sigilate.
                </Typography>
                <Typography>
                    Nu se acceptă produsele asupra cărora au fost efectuate intervenții
                    neautorizate, cele care prezintă urme de uzură sau folosire excesivă,
                    zgârieturi, ciobituri, lovituri, șocuri mecanice/electrice, produsele cărora
                    le lipsesc accesorii.
                </Typography>
                <Typography>
                    Pentru orice alte detalii sau neclarităţi privind returnarea produselor vă
                    rugăm să ne contactaţi la numarul de telefon afisat pe www.tabacshop.ro
                </Typography>
            </Container>

            <Footer />
            <BottomBar />
        </div>
    );
};

export default ReturnareProduse;