import React from 'react';
import ImageGallery from "react-image-gallery";

import "./ProductGalery.css";

import "react-image-gallery/styles/css/image-gallery.css";
import useMediaQuery from "@mui/material/useMediaQuery";


const ProductGalery = ({images}) => {

    const matches = useMediaQuery('(min-width:820px)');

    return (
        <div>
            <ImageGallery
                items={images}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                slideOnThumbnailOver={true}
                disableKeyDown={true}
                thumbnailPosition={matches ? "left" : "bottom"}
                disableThumbnailScroll="true"
            />
        </div>
    );
};

export default ProductGalery;