import React from 'react';

import CloseIcon from "@mui/icons-material/Close";
import {DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {styled, useTheme} from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";

import "./ProduseBottomBar.css";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import NavigationProducts from "../../NavigationProducts/NavigationProducts";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            <Typography className="products__title" component="div" variant="h6">
                <ShoppingBagIcon style={{marginRight: "10px", position: "relative", top: "4px"}}/>
                Produse
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
)
{
    return <Slide direction="down" ref={ref} {...props} />;
});

const ProduseBottomBar = ({open, handleClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <BootstrapDialog
            onClose={handleClose}
            onClick={handleClose}
            aria-labelledby="customized-dialog-title"
            fullWidth={true}
            maxWidth={'md'}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modal title
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <NavigationProducts/>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default ProduseBottomBar;